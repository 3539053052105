@tailwind base;
@tailwind components;
@tailwind utilities;

@import '~react-date-range/dist/styles.css';
@import '~react-date-range/dist/theme/default.css';

body {
  @apply font-primary;
}

/* TODO: Change this custom css */
abbr {
  text-decoration: none !important;
}
