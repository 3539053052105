@font-face {
  font-family: 'Roboto Condensed';
  src: url('RobotoCondensed-Light.ttf') format('truetype');
  font-weight: 300;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto Condensed';
  src: url('RobotoCondensed-LightItalic.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto Condensed';
  src: url('RobotoCondensed-Regular.ttf') format('truetype');
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto Condensed';
  src: url('RobotoCondensed-Italic.ttf') format('truetype');
  font-weight: 400;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto Condensed';
  src: url('RobotoCondensed-Bold.ttf') format('truetype');
  font-weight: 700;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto Condensed';
  src: url('RobotoCondensed-BoldItalic.ttf') format('truetype');
  font-weight: 700;
  font-style: italic;
  font-display: swap;
}
